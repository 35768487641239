import styled from "styled-components";
import { BLACK } from "../styles/colors";

export const Filter = styled("button")`
  line-height: 1.35em;
  font-family: "lucida-sans";
  font-size: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 6%;
  background: transparent;
  border: none;
  outline: none !important;
  position: relative;
  cursor: pointer;
  height: 48px;
  border: 2px solid ${BLACK};
  background-color: ${props => (props.isChecked ? BLACK : "transparent")};

  @media (min-width: 992px) {
    height: 64px;
  }

  @media (min-width: 992px) {
    font-size: 0.75rem;
  }

  @media (min-width: 1200px) {
    font-size: 0.85rem;
  }

  &:hover {
    background-color: ${BLACK};
    color: white;
  }
  color: ${props => (props.isChecked ? "white" : BLACK)};

  margin: 0px 4px 8px 0px;

  > label {
    padding: 0px 12px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }
`;
